export const speiseplan = {    
    periode:  "07.04. - 11.04.2025",
    
    zusatz:  "11:30 Uhr - 13:30 Uhr",

    montag: {
        sichtbar: true,
        gericht: "Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Rostbrätelpfanne",
        beigabe: "mit Krautsalat und Baguette",
        preis: "9,90 €"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Eier in Senfsoße",
        beigabe: "dazu Kartoffeln",                                   
        preis: "8,50 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "Gulasch",
        beigabe: "mit Nudeln",
        preis: "9,90 €"
    },

    freitag: {
        sichtbar: true,
        gericht: "Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },
};
